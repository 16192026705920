import '../styles/amplify.css'
import '../styles/global.css'
// eslint-disable-next-line import/no-unresolved
import '@aws-amplify/ui-react/styles.css'

import {
  BarChartOutlined,
  EditOutlined,
  IdcardOutlined,
  NotificationOutlined,
  QrcodeOutlined,
  SmileOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { ApolloProvider } from '@apollo/client'
import {
  Authenticator,
  translations,
  useAuthenticator,
} from '@aws-amplify/ui-react'
import { ConfigProvider } from 'antd'
import locale from 'antd/locale/ja_JP'
import { Amplify } from 'aws-amplify'
import { I18n } from 'aws-amplify/utils'
import { AppProps } from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import React, { useEffect, useState } from 'react'

import { useApollo } from '../apollo/apolloClient'
import { BasicLayout } from '../components/layouts'
import Analytics from '../utils/Analytics'
import ErrorTracker from '../utils/ErrorTracker'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID ?? '',
      userPoolClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID ?? '',
    },
  },
})

I18n.putVocabulariesForLanguage('ja', translations.ja)
I18n.setLanguage('ja')

const routes = {
  owner: {
    // TODO: 回数券リリース時に条件を外す
    children:
      process.env.NEXT_PUBLIC_STAGE === 'prd'
        ? [
            {
              path: '/',
              name: 'トップページ',
              icon: <SmileOutlined />,
            },
            {
              name: '企画券',
              icon: <IdcardOutlined />,
              children: [
                {
                  path: '/special_ticket/sales_summary',
                  name: '売上確認',
                  icon: <EditOutlined />,
                },
                {
                  path: '/special_ticket/start_usage_details',
                  name: '利用開始実績',
                  icon: <EditOutlined />,
                },
                {
                  path: '/special_ticket/products',
                  name: '商品管理',
                  icon: <EditOutlined />,
                },
                {
                  path: '/special_ticket/generate_qr',
                  name: 'QRコード生成',
                  icon: <QrcodeOutlined />,
                },
              ],
            },
            {
              name: '定期券',
              icon: <IdcardOutlined />,
              children: [
                {
                  name: '定期券の一覧',
                  path: '/simple_pass/my_passes',
                },
                {
                  name: '売上確認',
                  path: '/simple_pass/sales_summary',
                },
                {
                  name: '購入実績',
                  path: '/simple_pass/purchase_details',
                },
              ],
            },
            {
              name: '分析',
              icon: <BarChartOutlined />,
              children: [
                {
                  path: '/analytics/product_details',
                  name: '商品ごとの詳細分析',
                },
                {
                  path: '/analytics/product_cross_sell',
                  name: 'クロスセル分析',
                },
                {
                  path: '/analytics/ticket_usages',
                  name: 'チケット利用分析',
                },
                {
                  path: '/analytics/product_reviews',
                  name: 'レビュー（商品）',
                },
                {
                  path: '/analytics/ticket_reviews',
                  name: 'レビュー（チケット）',
                },
                {
                  name: '定期券利用者属性',
                  path: '/analytics/simple_pass_user_demographics',
                },
              ],
            },
            {
              path: '/push_notifications',
              name: 'PUSH通知配信',
              icon: <NotificationOutlined />,
            },
            {
              path: '/account_settings',
              name: 'アカウント',
              icon: <UserOutlined />,
            },
          ]
        : [
            {
              path: '/',
              name: 'トップページ',
              icon: <SmileOutlined />,
            },
            {
              name: '企画券',
              icon: <IdcardOutlined />,
              children: [
                {
                  path: '/special_ticket/sales_summary',
                  name: '売上確認',
                  icon: <EditOutlined />,
                },
                {
                  path: '/special_ticket/start_usage_details',
                  name: '利用開始実績',
                  icon: <EditOutlined />,
                },
                {
                  path: '/special_ticket/products',
                  name: '商品管理',
                  icon: <EditOutlined />,
                },
                {
                  path: '/special_ticket/generate_qr',
                  name: 'QRコード生成',
                  icon: <QrcodeOutlined />,
                },
              ],
            },
            {
              name: '定期券',
              icon: <IdcardOutlined />,
              children: [
                {
                  name: '定期券の一覧',
                  path: '/simple_pass/my_passes',
                },
                {
                  name: '売上確認',
                  path: '/simple_pass/sales_summary',
                },
                {
                  name: '購入実績',
                  path: '/simple_pass/purchase_details',
                },
              ],
            },
            {
              name: '回数券',
              icon: <IdcardOutlined />,
              children: [
                {
                  name: '回数券の一覧',
                  path: '/multi_ticket/user_items',
                },
                {
                  name: '売上確認',
                  path: '/multi_ticket/sales_summary',
                },
                {
                  name: '購入実績',
                  path: '/multi_ticket/purchase_details',
                },
              ],
            },
            {
              name: '分析',
              icon: <BarChartOutlined />,
              children: [
                {
                  path: '/analytics/product_details',
                  name: '商品ごとの詳細分析',
                },
                {
                  path: '/analytics/product_cross_sell',
                  name: 'クロスセル分析',
                },
                {
                  path: '/analytics/ticket_usages',
                  name: 'チケット利用分析',
                },
                {
                  path: '/analytics/product_reviews',
                  name: 'レビュー（商品）',
                },
                {
                  path: '/analytics/ticket_reviews',
                  name: 'レビュー（チケット）',
                },
                {
                  name: '定期券利用者属性',
                  path: '/analytics/simple_pass_user_demographics',
                },
                {
                  name: '回数券利用者属性',
                  path: '/analytics/multi_ticket_user_demographics',
                },
              ],
            },
            {
              path: '/push_notifications',
              name: 'PUSH通知配信',
              icon: <NotificationOutlined />,
            },
            {
              path: '/account_settings',
              name: 'アカウント',
              icon: <UserOutlined />,
            },
          ],
  },
  my_pass_reviewer: {
    children: [
      {
        name: '定期券',
        icon: <IdcardOutlined />,
        children: [
          {
            name: '定期券の一覧',
            path: '/simple_pass/my_passes',
          },
        ],
      },
    ],
  },
}

const useRevision = () => {
  const [isOldRevision, setIsOldRevision] = useState(false)
  useEffect(() => {
    Router.events.on('routeChangeComplete', async () => {
      const currentRevision = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA
      const result = await fetch('/revision.txt')
      const revision = await result.text()
      setIsOldRevision(currentRevision !== revision.trim())
    })
  }, [])

  return isOldRevision
}

const App = (props: AppProps) => (
  <Authenticator.Provider>
    <MyApp {...props} />
  </Authenticator.Provider>
)

const MyApp: React.FC<AppProps> = ({ Component, pageProps, router }) => {
  const apolloClient = useApollo()
  const { user: authUser, route: authRoute } = useAuthenticator()
  const user = { email: authUser?.signInDetails?.loginId ?? '' }
  ErrorTracker.setUser(user || null)

  const isOldRevision = useRevision()
  useEffect(() => {
    if (isOldRevision && process.env.NODE_ENV !== 'development') {
      window.location.reload()
    }
  }, [isOldRevision, router.pathname])

  useEffect(() => {
    Analytics.init()
    const handleRouteChange = (url: string) => {
      Analytics.logPageView(url)
    }
    Router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  return authRoute === 'authenticated' && user ? (
    <ApolloProvider client={apolloClient}>
      <Head>
        <link
          rel="stylesheet"
          type="text/css"
          href="/iconFonts/ryde/css/ryde.css"
        />
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <link
          href="https://cdn.jsdelivr.net/npm/@mdi/font@6.9.96/css/materialdesignicons.min.css"
          rel="stylesheet"
        />
      </Head>
      <ConfigProvider locale={locale}>
        <BasicLayout routes={routes}>
          <Component {...pageProps} />
        </BasicLayout>
      </ConfigProvider>
    </ApolloProvider>
  ) : (
    <Authenticator loginMechanisms={['email']} hideSignUp />
  )
}

export default App
